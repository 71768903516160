import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Link from '../Link'
import * as currenciesActions from '../../store/actions/currenciesActions'
import { createTheme } from '@mui/material/styles'
import { useStaticQuery, graphql } from 'gatsby'
import makeStyles from '@mui/styles/makeStyles'
import { Text, Button } from '../../components/Core'
import { Spinner, Row } from 'react-bootstrap'
import {
  fetchUserOpenOrders,
  fetchOrderTypes,
  fetchOrderStatusTypes,
  fetchOrdersHistory,
  cancelOrder,
} from '../../store/actions/userActions'
import * as styles from './Table.module.css'
import closeIcon from '../../assets/image/svg/ic_close.svg'
import WithTheme from '../../helpers/WithTheme'

const MUIDataTable = loadable(() => import('mui-datatables'))
const TableCell = loadable(() => import('@mui/material/TableCell'))
const TableRow = loadable(() => import('@mui/material/TableRow'))

function createData(
  rank,
  currLogo,
  displayName,
  exchangeName,
  orderType,
  quantity,
  price,
  status,
  date,
  time,
  canlcelOrder,
  closeDate,
  authId,
  coinigyOrderId
) {
  return {
    rank,
    currLogo,
    displayName,
    exchangeName,
    orderType,
    quantity,
    price,
    status,
    date,
    time,
    canlcelOrder,
    closeDate,
    authId,
    coinigyOrderId,
  }
}

const useStyles = makeStyles(theme => ({
  rightHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'right',
      //   backgroundColor:'red',
    },
  },
  centerHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //   backgroundColor:'red',
    },
  },
}))

const OrdersTable = () => {
  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''
  // const csrfCoinigy = useSelector(state => state.user.csrf_token);

  const dispatch = useDispatch()
  const { t, i18n, ready } = useTranslation(['tables'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const metaData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  )
  const langs = metaData.site.siteMetadata.languages
  let i18nLang
  let lang

  if (typeof i18n.language !== 'undefined') {
    i18nLang = i18n.language.includes('-')
      ? i18n.language.split('-')[0]
      : i18n.language
  }

  lang =
    typeof langs.find(lang => lang === i18nLang) !== 'undefined'
      ? langs.find(lang => lang === i18nLang)
      : 'en'

  const userCurrency = useSelector(state => state.user.selectedCurr)
  const currSign = useSelector(state => state.user.currSign)
  // const btcExchRate = useSelector(state => state.currencies.btcExchRate);
  const openOrders = useSelector(state => state.user.openOrders)
  const ordersHistory = useSelector(state => state.user.ordersHistory)
  const orderTypes = useSelector(state => state.user.orderTypes)
  const orderStatusTypes = useSelector(state => state.user.orderStatusTypes)
  const tableRows = useSelector(state => state.user.tableRows)


  useEffect(() => {
    dispatch(currenciesActions.fetchBtcExchRate(userCurrency))
  }, [dispatch, userCurrency])

  useEffect(() => {
    dispatch(fetchUserOpenOrders())
    dispatch(fetchOrdersHistory())
    dispatch(fetchOrderTypes())
    dispatch(fetchOrderStatusTypes())
  }, [dispatch])

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const cancelOrderHandler = async (newToken, authId, coinigyOrderId) => {
    await dispatch(cancelOrder(newToken, authId, coinigyOrderId))
    await dispatch(fetchUserOpenOrders())
  }

  const classes = useStyles()

  const columns = [
    {
      name: 'rank',
      label: ready && `${t('rank')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          style: {
            opacity: 0.6,
            width: '1rem',
          },
        }),
      },
    },
    {
      name: 'currLogo',
      label: ready && `${t('logo')}`,
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        setCellProps: () => ({
          style: {
            paddingRight: 0,
            background: userDarkMode ? '#191B20' : 'white',
          },
        }),
        customHeadRender: ({ index, ...column }) => {
          return <TableCell key={index}></TableCell>
        },
      },
    },
    {
      name: 'displayName',
      label: ready && `${t('market')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellProps: () => ({
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'exchangeName',
      label: ready && `${t('exchange')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellProps: () => ({
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'orderType',
      label: ready && `${t('type')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellProps: () => ({
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'quantity',
      label: ready && `${t('quantity')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'price',
      label: ready && `${t('price')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'status',
      label: ready && `${t('status')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellHeaderProps: () => ({
          className: classes.rightHeader,
          style: {
            paddingRight: 0,
          },
        }),
        setCellProps: () => ({
          align: 'right',
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        // customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },

    {
      name: 'date',
      label: ready && `${t('date')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellProps: () => ({
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        //   customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'time',
      label: ready && `${t('time')}`,
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        setCellHeaderProps: () => ({
          style: {
            paddingLeft: 3,
          },
        }),
        setCellProps: () => ({
          style: {
            fontVariant: ['tabular-nums'],
            whiteSpace: 'nowrap',
          },
        }),
        //   customBodyRender: value => {
        //     if (value >= 1) {
        //         return currSign + ' ' + value.toLocaleString(undefined,{ maximumFractionDigits: 2 });
        //     } else if (value === 0) {
        //         return '-'
        //     } else return currSign + ' ' + value.toLocaleString(undefined,{ minimumFractionDigits: 8 })
        // },
      },
    },
    {
      name: 'cancelOrder',
      label: ' ',
      options: {
        filterList: [],
        // filter: false,
        sort: false,
        setCellProps: () => ({
          align: 'right',
          style: {
            paddingTop: 12,
            paddingBottom: 12,
          },
        }),
      },
    },
    {
      name: 'closeDate',
      label: '',
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'authId',
      label: '',
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'coinigyOrderId',
      label: '',
      options: {
        // filter: true,
        filterList: [],
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ]

  let data = []

  const [showHistory, setShowHistory] = useState(false)

  const onFilter = value => {
    if (value === 'openOrders') {
      setShowHistory(false)
    }
    if (value === 'history') {
      setShowHistory(true)
    }
  }

  const options = {
    filter: false,
    download: true,
    print: false,
    // serverSide: true,
    // count: 100,
    rowsPerPageOptions: [10, 30, 50, 100],
    // onTableChange: (action, tableState) => {
    //     // console.log('ACTION', action, 'STATE', tableState)
    //     switch (action) {
    //         case "changePage":
    //             setPageNumber(tableState.page + 1);
    //         break;
    //         case "changeRowsPerPage":
    //             setPageSize(tableState.rowsPerPage);
    //         break;
    //         default:
    // 	        return;
    //     }
    // },
    textLabels: {
      body: {
        noMatch: completed ? (
          t('noMatchCurrencies')
        ) : (
          <div style={{ alignItems: 'center', minHeight: '200px' }}>
            <Spinner
              variant={userDarkMode ? 'light' : 'dark'}
              animation="border"
            />
          </div>
        ),
        toolTip: t('sort'),
        columnHeaderTooltip: column => `${t('sortBy')} ${column.label}`,
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCSV'),
        print: t('print'),
        viewColumns: t('viewColumns'),
        filterTable: t('filter'),
      },
      pagination: {
        next: t('nextPage'),
        previous: t('previousPage'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('of'), // 1-10 of 30
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('showColumnsAria'),
      },
    },

    filterType: 'multiselect',
    responsive: 'standard',
    selectableRowsHeader: false,
    selectableRows: 'none',
    customRowRender: data => {
      // let marketNameLowCase = data[10].toLowerCase();
      let currSlug = data[8]
      // const handleRowClick = () => navigate(`/${lang}/currencies/${currSlug}/`);
      let price =
        typeof data[6] !== 'undefined'
          ? currSign +
            ' ' +
            data[6].toLocaleString(undefined, { maximumFractionDigits: 2 })
          : 0
      let authId = data[11]
      let coinigyOrderId = data[12]
      return (
        <TableRow hover={true} key={Math.random()}>
          <TableCell style={{ textAlign: 'right' }}>
            <Link aria-label={currSlug} to={`/${lang}/currencies/${currSlug}/`}>
              <span
                style={{
                  color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
                  fontFamily: 'CircularStd, sans-serif',
                }}
              >
                {data[0]}
              </span>
            </Link>
          </TableCell>
          <TableCell style={{ paddingRight: 0, paddingLeft: 4 }}>
            {data[1]}
          </TableCell>
          <TableCell
            style={{
              paddingRight: 0,
              color: userDarkMode ? '#FAFAFA' : '#191B20',
              fontWeight: 700,
              fontSize: 16,
            }}
          >
            {data[2]}
          </TableCell>
          <TableCell
            style={{
              color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            }}
          >
            {data[3]}
          </TableCell>
          <TableCell>{data[4]}</TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              fontVariant: ['tabular-nums'],
            }}
          >
            {data[5]}
          </TableCell>
          <TableCell
            style={{
              textAlign: 'right',
              fontVariant: ['tabular-nums'],
            }}
          >
            {price}
          </TableCell>
          <TableCell style={{ textAlign: 'right', paddingRight: 0 }}>
            {data[7]}
          </TableCell>
          <TableCell
            style={{
              paddingRight: 0,
              color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            }}
          >
            {data[8]}
          </TableCell>
          <TableCell
            style={{
              paddingRight: 0,
              paddingLeft: 3,
              color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            }}
          >
            {data[9]}
          </TableCell>
          <TableCell>
            <Button
              className={styles.aTagButton}
              style={{ padding: '0.4rem' }}
              bg="#D0433B !important"
              onClick={() =>
                cancelOrderHandler(newToken, authId, coinigyOrderId)
              }
            >
              <img
                width="24px"
                height="24px"
                alt={t('cancelOrder')}
                src={closeIcon}
              />
            </Button>
          </TableCell>
        </TableRow>
      )
    },
    rowsPerPage: tableRows,
  }

  showHistory === false
    ? openOrders.map(function (order, index) {
        let currLogo = (
          <img
            width="24"
            height="24"
            src={
              process.env.WWW_URL +
              `/assets/img/currency/${order.baseCurrency}.webP`
            }
            alt={`${order.baseCurrency} Logo`}
            onError={e => {
              e.target.onerror = null
              e.target.src =
                process.env.WWW_URL + `/assets/img/currency/empty.webP`
            }}
          />
        )
        // let exchLogo = <img width="20" height="20" className="mr-2"
        //     src={ process.env.WWW_URL + `/assets/img/exchange/${order.exchCode}-icon.webP`}
        //     alt={`${order.exchName} Logo`}
        //     onError={
        //     (e)=>{
        //         e.target.onerror = null; e.target.src = process.env.WWW_URL + `/assets/img/currency/empty.webP`;
        //     }
        //     }  />
        // ;

        let orderSide =
          order.side === 0
            ? 'Unknown'
            : order.side === 1
            ? 'Buy'
            : order.side === 2
            ? 'Sell'
            : ''
        let orderTypeString =
          typeof orderTypes !== 'undefined'
            ? orderTypes.find(({ id }) => id === order.orderType)
            : ''
        let orderStatusString =
          typeof orderStatusTypes !== 'undefined'
            ? orderStatusTypes.find(({ id }) => id === order.status)
            : ''
        // let price = btcExchRate ? order.limitPrice * btcExchRate : order.limitPrice;
        let orderDate = order.openTime !== null ? order.openTime.split('T') : ''
        let orderTime =
          typeof orderDate[1] !== 'undefined'
            ? orderDate[1].replace('Z', '')
            : ''
        return data.push(
          createData(
            index + 1,
            currLogo,
            order.displayName,
            order.exchange,
            typeof orderTypeString !== 'undefined'
              ? orderTypeString.name + ' ' + orderSide
              : '',
            order.quantity,
            order.limitPrice,
            typeof orderStatusString !== 'undefined'
              ? orderStatusString.name
              : '',
            orderDate[0],
            orderTime,
            'cancelOrder',
            order.authId,
            order.coinigyOrderId
          )
        )
      })
    : showHistory === true
    ? ordersHistory.map(function (order, index) {
        let currLogo = (
          <img
            width="24"
            height="24"
            src={
              process.env.WWW_URL +
              `/assets/img/currency/${order.baseCurrency}.webP`
            }
            alt={`${order.baseCurrency} Logo`}
            onError={e => {
              e.target.onerror = null
              e.target.src =
                process.env.WWW_URL + `/assets/img/currency/empty.webP`
            }}
          />
        )
        // let exchLogo = <img width="20" height="20" className="mr-2"
        //     src={ process.env.WWW_URL + `/assets/img/exchange/${order.exchCode}-icon.webP`}
        //     alt={`${order.exchName} Logo`}
        //     onError={
        //     (e)=>{
        //         e.target.onerror = null; e.target.src = process.env.WWW_URL + `/assets/img/currency/empty.webP`;
        //     }
        //     }  />
        // ;
        let orderSide =
          order.side === 0
            ? 'Unknown'
            : order.side === 1
            ? 'Buy'
            : order.side === 2
            ? 'Sell'
            : ''
        let orderTypeString =
          typeof orderTypes !== 'undefined'
            ? orderTypes.find(({ id }) => id === order.orderType)
            : ''
        let orderStatusString =
          typeof orderStatusTypes !== 'undefined'
            ? orderStatusTypes.find(({ id }) => id === order.status)
            : ''
        // let price = btcExchRate ? order.limitPrice * btcExchRate : order.limitPrice;
        let orderDate =
          order.closeTime !== null ? order.closeTime.split('T') : ''
        let orderTime =
          typeof orderDate[1] !== 'undefined'
            ? orderDate[1].replace('Z', '')
            : ''
        return data.push(
          createData(
            index + 1,
            currLogo,
            order.displayName,
            order.exchange,
            typeof orderTypeString !== 'undefined'
              ? orderTypeString.name + ' ' + orderSide
              : '',
            order.quantity,
            order.limitPrice,
            typeof orderStatusString !== 'undefined'
              ? orderStatusString.name
              : '',
            orderDate[0],
            orderTime,
            'cancelOrder',
            order.authId,
            order.coinigyOrderId
          )
        )
      })
    : (data = [])

  return ready && (
    <MUIDataTable
      title={
        <div>
          <div className="mt-4 ml-3">
            <Row>
              <div className="mr-5">
                <Text
                  style={{
                    color: '#2F5C91',
                    fontWeight: 900,
                    cursor: 'pointer',
                  }}
                  onClick={() => onFilter('openOrders')}
                  variant={'small'}
                >
                  {t('openOrders')}
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    color: '#2F5C91',
                    fontWeight: 900,
                    cursor: 'pointer',
                  }}
                  onClick={() => onFilter('history')}
                  variant={'small'}
                >
                  {t('history')}
                </Text>
              </div>
            </Row>
          </div>
        </div>
      }
      data={data}
      columns={columns}
      options={options}
    />
  )
}

const muiTheme = ({ userDarkMode }) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontFamily: 'CircularStd, sans-serif',
          },
          underline: {
            '&:before': { borderBottom: '2px solid transparent !important' },
            '&:after': { borderBottom: '2px solid #3377C6 !important' },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {},
          input: {
            backgroundColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)',
            paddingTop: 14,
            paddingRight: 16,
            paddingBottom: 15,
            paddingLeft: 16,
            borderRadius: 5,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
        },
      },
      MUIDataTableSearch: {
        styleOverrides: {
          searchIcon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56)'
              : 'rgba(0, 0, 0, 0.56)',
          },
          searchText: {
            maxWidth: 400,
          },
        },
      },
      MUIDataTableViewCol: {
        styleOverrides: {
          label: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
          title: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            '&:hover': {
              backgroundColor: userDarkMode
                ? 'rgba(255, 255, 255, 0.1) !important'
                : 'rgba(0, 0, 0, 0.04) !important',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: { fontFamily: 'CircularStd, sans-serif', margin: '20px 0' },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          caption: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0em',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            opacity: 0.6,
          },
          select: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: '#3377C6',
            '&:focus': { borderRadius: '10px' },
            backgroundColor: 'transparent',
          },
          selectLabel: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          displayedRows: {
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '14px',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            marginBottom: '2px',
            opacity: 0.6
          },
          selectIcon: { color: '#3377C6' },
          actions: {
            color: userDarkMode ? '#3377C6' : '#3377C6',
            backgroundColor: 'transparent',
          },
          root: { zIndex: 999 },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif' },
          paper: {
            borderRadius: 0,
            overflow: 'hidden',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
          },
          toolButton: {
            backgroundColor: 'transparent',
            fontFamily: 'CircularStd, sans-serif',
            marginRight: '-8px',
            fontWeight: 300,
          },
          fixedHeader: {
            backgroundColor: 'transparent',
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
            fontWeight: 300,
          },
          data: {
            // backgroundColor: userDarkMode ? '#191B20' : 'white' ,
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          sortActive: {
            color: userDarkMode ? '#828282' : 'rgba(0, 0, 0, 0.56)',
          },
          contentWrapper: {},
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: userDarkMode
              ? 'rgba(255, 255, 255, 0.56) !important'
              : 'rgba(0, 0, 0, 0.56) !important',
            marginTop: '3px',
            marginRight: '0',
          },
        },
      },
      MuiSelected: {
        styleOverrides: {
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': {
              color: '#3377C6',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiTableFooter: {
        styleOverrides: { root: { backgroundColor: 'transparent' } },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif', fontWeight: 300 },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            minHeight: '80px !important',
            paddingLeft: '0px',
            paddingRight: '0px',
            zIndex: 999,
          },
          icon: {
            color: userDarkMode ? '#828282' : 'gray',
            '&:hover': {
              color: '#3377C6',
            },
          },
          titleText: {
            color: userDarkMode ? '#3986F7' : '#185398',
            fontWeight: 900,
            fontFamily: 'CircularStd, sans-serif',
            fontSize: '24px !important',
            textTransform: 'uppercase',
            letterSpacing: '-0.02em',
          },
          actions: {
            display: 'flex',
            flex: 'initial',
            '& > span, & > button': { order: 99 },
            '& > span:last-child, & > button:last-child': { order: 1 },
            '& > span:nth-child(4), & > button:nth-child(4)': { order: 2 },
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: { headerCell: { backgroundColor: 'transparent' } },
      },
      MuiTableCell: {
        styleOverrides: {
          head: { fontWeight: 300 },
          body: {
            fontSize: '14px',
            fontFamily: 'CircularStd, sans-serif',
            cursor: 'pointer',
            color: userDarkMode ? '#FAFAFA' : 'black',
          },
          root: {
            borderBottomColor: userDarkMode
              ? 'rgba(255, 255, 255, 0.1) !important'
              : 'light',
            fontFamily: 'CircularStd, sans-serif',
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontWeight: 300,
          },
        },
      },
    },
    mixins: {},
    palette: {
      text: { hint: 'rgba(0, 0, 0, 0.38)' },
      mode: 'light',
      type: 'light',
    },
  })

export default WithTheme(muiTheme)(OrdersTable)
