import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { useSelector } from 'react-redux'

const WithTheme = theme => {
  return WrappedComponent => {
    let Component = props => {
      const userSettings = useSelector(state => state.user.user_settings)
      const userSessionExpired = useSelector(
        state => state.user.session_expired
      )
      const userDarkModeSelector = useSelector(state => state.user.darkMode)
      const userDarkMode = userSessionExpired
        ? userDarkModeSelector
        : typeof userSettings !== 'undefined'
        ? userSettings.dark_mode
        : false

      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme({ userDarkMode })}>
            <WrappedComponent {...props} />
          </ThemeProvider>
        </StyledEngineProvider>
      )
    }

    return Component
  }
}

export default WithTheme
